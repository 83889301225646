/* eslint-disable max-lines */
import { useCallback, useState } from "react";
import { ArrowButton, Footer, HomeModal } from "@bay1/ui";
import { MarketingNav } from "@bay1/ui/components/MarketingNav";
import Head from "next/head";
import Link from "next/link";
// eslint-disable-next-line @typescript-eslint/no-shadow
import Image from "next/image";

import { formium } from "../lib/formium";
import { MainHeroGraphic } from "../components/animations/icons/MainHeroGraphic";
import { PlatformHeroGraphic } from "../components/animations/icons/PlatformHeroGraphic";
import { FinancialOpsIcon } from "../components/animations/icons/FinancialOpsIcon";
import { StreamlinedIcon } from "../components/animations/icons/StreamlinedIcon";
import { GrowthIcon } from "../components/animations/icons/GrowthIcon";
import { HeroTextIntro } from "../components/animations/icons/HeroTextIntro";
import { CodeSnippet } from "../components/animations/icons/CodeSnippet";
import { DividerAnimation } from "../components/animations/icons/DividerAnimation";
import { FooterCTA } from "../components/FooterCTA";

const Marketing = (): JSX.Element => {
  const [modalProperties, setModalProperties] = useState({
    isVisible: false,
    headline: "",
    message: "",
  });

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleNewsletterFormSuccess = useCallback(async () => {
    setModalProperties({
      isVisible: true,
      headline: "Thanks for signing up",
      message: "The Highnote team will be in touch.",
    });
  }, []);

  return (
    <>
      <Head>
        <title>
          Highnote | Most Modern Card Issuer Processor and Program Management
          Platform
        </title>
        <meta
          content="Harness the power of a customer-first all-in-one modern card issuer processing and program management platform to quickly build and launch new revenue streams for your digital business."
          name="description"
        />
      </Head>
      {/* Marquee Section */}
      <div className="bg-gradient-to-b from-white to-bone">
        <MarketingNav backgroundClass="bg-transparent" />
        <HomeModal {...modalProperties} />
        <main className="lg:relative pt-8 pb-0 px-4 lg:pt-16 lg:pb-40 lg:py-27 lg:px-10 flex-auto">
          <div className="mx-auto relative max-w-7xl w-full">
            <div className="w-full">
              <h1 className="headline lg:text-7xl text-4xl font-display">
                <HeroTextIntro />
              </h1>
            </div>
          </div>
          <div className="container mx-auto w-full max-w-7xl justify-between lg:flex">
            <div className="lg:w-1/2 w-full">
              <p className="lg:text-base lg:pt-7 pt-4">
                Highnote is the all-in-one card issuer processor and program
                management platform that gives digital-first organizations the
                flexibility to easily issue/process payment cards that
                accelerate business growth and profitability.
              </p>
              <Link href="/request-demo">
                <a className="button button-large button-green mt-12">
                  Request Demo <ArrowButton isPrimary />
                </a>
              </Link>
            </div>
            <div className="relative w-2/3 mx-auto mt-10 lg:-mt-10">
              <MainHeroGraphic />
              <img alt="" className="sm:hidden" src="img/card.svg" />
            </div>
          </div>
        </main>
      </div>
      {/* Why Highnote */}
      <section className="bg-bone px-4 py-10 pt-0 lg:py-20 lg:px-10">
        <div className="container bg-white items-center justify-between mx-auto relative max-w-7xl rounded-highnote px-4 py-10 lg:px-12 lg:py-14">
          <div className="w-full">
            <div className="mx-auto space-y-10">
              <h2 className="text-2xl lg:text-6xl font-display">
                Financial experiences built for tomorrow, not yesterday
              </h2>
              <Link href="/why-highnote">
                <a className="button button-black mt-12">
                  Learn Why Highnote <ArrowButton isPrimary />
                </a>
              </Link>
            </div>
          </div>
          <div className="w-full">
            <div className="lg:flex lg:space-x-20 space-y-20 lg:space-y-0 justify-between pt-16">
              <div className="w-full lg:w-1/3">
                <div className="flex flex-col space-y-4 items-start">
                  <div className="h-20">
                    <StreamlinedIcon />
                  </div>
                  <p className="text-xl font-display pt-2">
                    Streamlined Launch
                  </p>
                  <p className="text-sm">
                    Highnote&apos;s developer-friendly card issuance platform
                    smooths the path to launch with ready-to-go collateral
                    templates and streamlined banking relationships to get your
                    finance experience launched quickly.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/3">
                <div className="flex flex-col space-y-4">
                  <div className="h-20">
                    <GrowthIcon />
                  </div>
                  <p className="text-xl font-display pt-2">
                    Accelerate your Build
                  </p>
                  <p className="text-sm">
                    Highnote makes the hardest parts of fintech simple. Our
                    issuer processor platform with its immutable double-entry
                    accounting ledger offers full visibility and control of your
                    financial and business operations.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/3">
                <div className="flex flex-col space-y-4">
                  <div className="h-20">
                    <FinancialOpsIcon />
                  </div>
                  <p className="text-xl font-display pt-2">Built for Growth</p>
                  <p className="text-sm">
                    Built to grow, scale, and adapt with your business&apos;s
                    needs, Highnote enables increased innovation and new revenue
                    sources by serving as an all-in-one card issuing and program
                    management platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Platform */}
      <section className="bg-bone px-4 pt-8 pb-10 lg:py-20 lg:px-10">
        <div className="container items-center justify-between mx-auto relative max-w-7xl">
          <div className="w-full">
            <div className="lg:w-2/3">
              <h2 className="text-2xl lg:text-6xl font-display">
                Complete platform for embedded finance
              </h2>
            </div>
          </div>
          <div className="w-full lg:flex mt-8">
            <div className="lg:pr-10 lg:w-1/2">
              <p className="text-base">
                Highnote provides the tools for you to create unique card
                products true to your brand. Enhance your customer experience
                while growing revenue and loyalty.
              </p>
              <div className="w-full flex lg:space-x-20 space-x-10 mt-10">
                <div className="w-auto">
                  <div className="space-y-4">
                    <div className="flex items-start space-x-2">
                      <img alt="" src="img/check-green-sm.svg" />
                      <p>Issuing</p>
                    </div>
                    <div className="flex items-start space-x-2">
                      <img alt="" src="img/check-green-sm.svg" />
                      <p>Processing</p>
                    </div>
                    <div className="flex items-start space-x-2">
                      <img alt="" src="img/check-green-sm.svg" />
                      <p>Ledger</p>
                    </div>
                  </div>
                </div>
                <div className="w-auto">
                  <div className="space-y-4">
                    <div className="flex items-start space-x-2">
                      <img alt="" src="img/check-green-sm.svg" />
                      <p>Financial Operations</p>
                    </div>
                    <div className="flex items-start space-x-2">
                      <img alt="" src="img/check-green-sm.svg" />
                      <p>Compliance</p>
                    </div>
                  </div>
                </div>
              </div>
              <Link href="/platform">
                <a className="button button-black mt-12">
                  Learn About Our Platform <ArrowButton isPrimary />
                </a>
              </Link>
            </div>
            <div className="lg:w-1/2 lg:-mt-20 mt-12">
              <PlatformHeroGraphic />
              <img
                alt=""
                className="sm:hidden"
                src="img/PlatformHeroGraphic.svg"
              />
            </div>
          </div>
        </div>
      </section>
      {/* API Docs */}
      <section className="bg-bone px-4 py-10 lg:py-20 lg:px-10">
        <div className="container border border-1 border-black items-center justify-between mx-auto relative max-w-7xl rounded-highnote px-4 py-10 lg:px-12 lg:py-14 lg:flex">
          <div className="lg:w-1/2">
            <div className="max-w-xl mx-auto space-y-10 lg:ml-0 lg:pr-10">
              <h2 className="text-2xl lg:text-6xl font-display">
                Build for your brand
              </h2>
              <p className="text-base">
                Highnote’s GraphQL API allows tailored, client-centric access to
                our platform and offers full-flexibility to build great brand
                experiences for your customers in a way that works with the
                systems and processes your company already has in place.
              </p>
            </div>
          </div>
          <div className="space-y-12 lg:w-1/2 lg:mt-0 mt-10 overflow-hidden relative">
            <div className="bg-black rounded-highnote font-mono">
              <CodeSnippet />
            </div>
          </div>
        </div>
      </section>
      {/* Section Grow */}
      <section className="bg-bone px-4 pt-8 pb-10 lg:py-28 lg:px-10 lg:pb-40">
        <div className="container items-center mx-auto relative max-w-7xl">
          <div className="lg:pr-4">
            <h2 className="text-2xl lg:text-7xl font-display mb-10">
              <div className="mb-4">
                Grow Your{" "}
                <span className="inline-block lg:h-28 h-14 bg-gradient-to-r from-green to-yellow rounded-highnote px-2">
                  Revenue
                </span>
                .
              </div>
              <div className="mb-4">
                Grow Your{" "}
                <span className="inline-block lg:h-28 h-14 bg-gradient-to-r from-green to-yellow rounded-highnote px-2">
                  Loyalty
                </span>
                .
              </div>
              Grow With Highnote.
            </h2>
          </div>
        </div>
      </section>
      {/* Particle Divider */}
      <section className="bg-white">
        <DividerAnimation />
      </section>
      {/* Resources */}
      <section className="bg-white px-4 pt-8 pb-10 lg:py-20 lg:px-10">
        <div className="container items-center justify-between lg:flex mx-auto relative max-w-7xl pb-14">
          <div className="w-full">
            <div className="mx-auto space-y-10">
              <h3 className="text-2xl lg:text-6xl font-display">
                Resources to keep you informed.
              </h3>
              <Link href="/blog">
                <a className="button button-black">
                  Highnote Blog <ArrowButton isPrimary />
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className="group container md:flex mx-auto relative max-w-7xl space-y-12 md:space-y-0 space-x-0 md:space-x-20">
          <div className="md:w-1/2 cursor-pointer bg-bone rounded-highnote transform duration-500 ease-in-out hover:scale-101 border-2 border-transparent hover:border-green">
            <Link href="/blog/why-we-built-highnote">
              <div className="block md:grid items-center p-5">
                <Image
                  alt="Highnote Co-founders"
                  className="rounded-highnote w-full object-cover"
                  height={400}
                  src="https://media.graphcms.com/uqMuNyPTTtOUhFiInXzp"
                  width={400}
                />
                <div className="items-center space-y-5 my-8">
                  <p className="font-medium uppercase text-xs">From the Blog</p>
                  <h3 className="text-xl">
                    Why We Built Highnote: A Q&amp;A with Highnote Cofounders
                    John MacIlwaine and Kin Kee
                  </h3>
                  <p className="mt-5 text-base hover:underline">
                    Read more&nbsp;
                    <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="md:w-1/2 cursor-pointer bg-bone rounded-highnote transform duration-500 ease-in-out hover:scale-101 border-2 border-transparent hover:border-green">
            <Link href="/blog/highnote-emerges-from-stealth-with-54-million-in-funding">
              <div className="block md:grid items-center p-5">
                <Image
                  alt="Highnote Funding"
                  className="rounded-highnote w-full object-cover"
                  height={400}
                  src="https://media.graphcms.com/XLEnpsUQlia5JErCUViu"
                  width={400}
                />
                <div className="items-center space-y-5 my-8">
                  <p className="font-medium uppercase text-xs">From the Blog</p>
                  <h3 className="text-xl">
                    Highnote Emerges from Stealth with $54 Million in Funding
                  </h3>
                  <p className="mt-5 text-base hover:underline">
                    Read more&nbsp;
                    <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <FooterCTA />
      <Footer
        formiumClient={formium}
        onNewsletterFormSuccess={handleNewsletterFormSuccess}
      />
    </>
  );
};

export default Marketing;
