import React from "react";

import styles from "./StreamlinedIcon.module.css";

export const StreamlinedIcon = (): JSX.Element => (
  <div className={styles.streamlined}>
    <svg
      fill="none"
      height="80"
      viewBox="0 0 80 80"
      width="80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="40" r="4" stroke="black" strokeWidth="2" />
      <circle cx="40" cy="5" r="4" stroke="black" strokeWidth="2" />
      <circle cx="75" cy="40" r="4" stroke="black" strokeWidth="2" />
      <circle cx="40" cy="75" r="4" stroke="black" strokeWidth="2" />
      <circle cx="16" cy="64" r="4" stroke="black" strokeWidth="2" />
      <circle cx="16" cy="16" r="4" stroke="black" strokeWidth="2" />
      <circle cx="64" cy="64" r="4" stroke="black" strokeWidth="2" />
      <circle cx="64" cy="16" r="4" stroke="black" strokeWidth="2" />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="40"
        x2="40"
        y1="49"
        y2="71"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="40"
        x2="40"
        y1="9"
        y2="31"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="40"
        x2="40"
        y1="9"
        y2="31"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="31"
        x2="9"
        y1="40"
        y2="40"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="71"
        x2="49"
        y1="40"
        y2="40"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="33.9286"
        x2="18.3723"
        y1="34.3428"
        y2="18.7865"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="61.2128"
        x2="45.6565"
        y1="61.627"
        y2="46.0707"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="33.3428"
        x2="17.7865"
        y1="46.0714"
        y2="61.6277"
      />
      <line
        className={styles.stroke}
        stroke="black"
        strokeDasharray="2 4"
        strokeWidth="2"
        x1="61.627"
        x2="46.0707"
        y1="18.7872"
        y2="34.3435"
      />
    </svg>
    <div className={styles.centerCircle} />
  </div>
);
