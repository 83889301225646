import React from "react";

import styles from "./HeroTextIntro.module.css";

export const HeroTextIntro = (): JSX.Element => (
  // eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers
  <div>
    <span className={styles.mask}>
      <div className={styles.reveal}>The</div>
    </span>

    <span className={styles.mask}>
      <div className={styles.reveal}>World&#39;s</div>
    </span>

    <span className={styles.mask}>
      <div className={styles.reveal}>Most</div>
    </span>

    <span className={styles.mask}>
      <div className={styles.reveal}>Modern</div>
    </span>

    <span className={styles.mask}>
      <div className={styles.reveal}>Card</div>
    </span>

    <span className={styles.mask}>
      <div className={styles.reveal}>Platform</div>
    </span>
  </div>
);
