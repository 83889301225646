import React from "react";

import styles from "./GrowthIcon.module.css";

export const GrowthIcon = (): JSX.Element => (
  <div className={styles.growth2}>
    <svg
      fill="none"
      height="81"
      viewBox="0 0 82 81"
      width="82"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height="18"
        rx="3"
        stroke="black"
        strokeWidth="2"
        width="18"
        x="1"
        y="62.001"
      />
      <path
        className={styles.stroke}
        d="M1 16.001H65V80.001H5C2.79086 80.001 1 78.2101 1 76.001V16.001Z"
        stroke="black"
        strokeDasharray="2 6"
        strokeWidth="2"
      />
      <path
        className={styles.stroke}
        d="M2 48.001H33V79.001"
        stroke="black"
        strokeDasharray="2 6"
        strokeWidth="2"
      />
      <path
        className={styles.stroke}
        d="M2 32.001H49V79.001"
        stroke="black"
        strokeDasharray="2 6"
        strokeWidth="2"
      />
      <path
        className={styles.arrow}
        d="M18.495 63.1131L74.1131 7.49493M74.1131 7.49493L63.1225 7.49492M74.1131 7.49493L74.1131 18.4855"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  </div>
);
