import React from "react";

import styles from "./CodeSnippet.module.css";

export const CodeSnippet = (): JSX.Element => (
  <div className={styles.code}>
    <code>
      <pre>
        <div className={`${styles.numbers} text-gray-500`}>
          <span aria-disabled="true" className={styles.number}>
            1
          </span>
          <span aria-disabled="true" className={styles.number}>
            2
          </span>
          <span aria-disabled="true" className={styles.number}>
            3
          </span>
          <span aria-disabled="true" className={styles.number}>
            4
          </span>
          <span aria-disabled="true" className={styles.number}>
            5
          </span>
          <span aria-disabled="true" className={styles.number}>
            6
          </span>
          <span aria-disabled="true" className={styles.number}>
            7
          </span>
          <span aria-disabled="true" className={styles.number}>
            8
          </span>
          <span aria-disabled="true" className={styles.number}>
            9
          </span>
        </div>
        <div className={styles.area}>
          <div className={styles.row1}>
            <span className={styles.keyword}>mutation</span>{" "}
            <span className={styles.entity}>CreateCardProduct</span>
            <span>(</span>
            <span className={styles.variable}>$input</span>
            <span>:</span>{" "}
            <span className={styles.constant}>CreateCardProductInput</span>
            <span className={styles.keyword}>!</span>
            <span>)</span> <span>&#123;</span>
          </div>
          <div className={styles.row2}>
            <span className={styles.variable}>createCardProduct</span>
            <span className="class">(</span>
            <span className={styles.variable}>input</span>
            <span>:</span> <span className={styles.variable}>$input</span>
            <span className="class">)</span> <span>&#123;</span>
          </div>
          <div className={styles.row3}>
            <span className={styles.keyword}>...</span>{" "}
            <span className={styles.keyword}>on</span>{" "}
            <span className={styles.constant}>CardProduct</span>{" "}
            <span>&#123;</span>
          </div>
          <div className={styles.row4}>
            <span className={styles.variable}>id</span>
          </div>
          <div className={styles.row5}>
            <span className={styles.variable}>name</span>
          </div>

          <div className={styles.row7}>
            <span className={styles.variable}>usage</span>
          </div>
          <div className={styles.row8}>
            <span>&#125;</span>
          </div>
          <div className={styles.row9}>
            <span>&#125;</span>
          </div>
          <div className={styles.row}>
            <span>&#125;</span>
          </div>
        </div>
      </pre>
    </code>
  </div>
);
